import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useCartContext } from "../../../providers/cart"

export const withCartList = Component => ({ name = "CartList" }: any) => {
  const { cart:cartContext, count } = useCartContext()

  const { cart } = useStaticQuery(graphql`
    query SANITY_PAGE_CART_LIST {
      cart: sanityPageCart {
        additionalLineItemsListTitle
        additionalLineItemsTotalText
      }
    }
  `)

  const { additionalLineItemsListTitle, additionalLineItemsTotalText } = cart || {}

  Component.displayName = name
  return (
    <Component
      lineItems={cartContext.lines}
      count={count}
      additionalLineItemsListTitle={additionalLineItemsListTitle}
      additionalLineItemsTotalText={additionalLineItemsTotalText}
    />
  )
}
