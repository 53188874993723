import React from "react"
import { useCartContext } from "../../providers/cart"

export const withCart = Component => ({ name = "Cart", page }) => {
  const { count, initializedLoading: loading } = useCartContext()
  const { title } = page

  const paths = [{ title }]

  Component.displayName = name
  return <Component paths={paths} count={count} loading={loading} />
}
